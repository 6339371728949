import React from "react";
import { useTranslation } from "react-i18next";
import { combineClassNames } from "../utils";

type ButtonType = "secondary" | "cta" | "tertiary" | "ghost";
type ButtonSize = "big" | "medium" | "small" | "xsmall";

interface ButtonProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "type"> {
  buttonType?: "submit" | "button" | "reset";
  type?: ButtonType;
  size?: ButtonSize;
  text?: React.ReactNode;
  big?: boolean;
  medium?: boolean;
  small?: boolean;
  cta?: boolean;
  secondary?: boolean;
  ghost?: boolean;
  block?: boolean;
  noTranslate?: boolean;
}

export function Button(props: ButtonProps) {
  const { t } = useTranslation();
  const {
    type,
    size,
    text,
    children,
    className,
    color,
    big,
    small,
    medium,
    cta,
    ghost,
    secondary,
    block,
    buttonType,
    ...restAttrs
  } = props;
  const btnSize = getButtonSize(props);
  const btnType = getButtonTypeClass(props);
  const classes = combineClassNames(
    "btn",
    btnType ? `btn-${btnType}` : undefined,
    btnSize ? `btn-${btnSize}` : undefined,
    block ? "btn-block" : undefined,
    className
  );

  let childrenContent = props.children || props.text;
  if (typeof childrenContent === "string") {
    childrenContent = t(childrenContent);
  }

  return (
    <button type={buttonType || "button"} className={classes} {...restAttrs}>
      {childrenContent}
    </button>
  );
}

function getButtonTypeClass(props: ButtonProps): ButtonType {
  if (props.type) return props.type;

  if (props.cta) return "cta";
  if (props.secondary) return "secondary";
  if (props.ghost) return "ghost";

  return "tertiary";
}

function getButtonSize(props: ButtonProps): ButtonSize {
  if (props.size) return props.size;
  if (props.big) return "big";
  if (props.medium) return "medium";
  if (props.small) return "small";

  return "xsmall";
}
