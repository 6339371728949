import React, { Component } from "react";
import { Placement } from "popper.js";
import { ClickableIcon, combineClassNames, Icon, KeyCodes } from "core/utils";
import { SelectView } from "./SelectView";
import {
  selectUtils,
  ItemDisplayFn,
  ItemRenderFn,
  ItemsRenderFn,
  ItemSearchValueFn,
  ItemFiltering,
} from "../utils/selectUtils";
import { AsyncItemsProvider } from "../utils/asyncItemsProvider";
import { TrCtx, TrFunction } from "core/intl";

interface SelectInputProps<TItem> extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "value" | "onChange"> {
  value: any | undefined;
  items: TItem[] | AsyncItemsProvider<TItem>;
  itemKey?: keyof TItem;
  itemKeyAsModel?: boolean;
  itemRender?: ItemRenderFn<TItem>;
  itemsRender?: ItemsRenderFn<TItem>;
  display?: keyof TItem | ItemDisplayFn<TItem>;
  itemAnnotation?: keyof TItem | ItemDisplayFn<TItem>;
  searchValue?: keyof TItem | ItemSearchValueFn<TItem>;
  placement?: Placement;
  nonStrict?: boolean;
  emptyOption?: boolean;
  hideClearIcon?: boolean;
  hideHelperIcons?: boolean;
  disableNoResultFeedback?: boolean;
  pendingSpinner?: boolean;
  onChange: (value?: TItem) => any;
  onChangeNonStrict?: (value?: string) => any;
  errorAnnotation?: string;
  filtering?: ItemFiltering<TItem>;
  wrapperClassName?: string;
  noTranslate?: boolean;
}

interface SelectInputState {
  inputValue: string;
  isDirty: boolean;
  isInputFocused: boolean;
  filterToken: string;
  selectVisible: boolean;
}

export class SelectInput<TItem> extends Component<SelectInputProps<TItem>, SelectInputState> {
  static rootClassName = "n-select";
  static defaultPlacement: Placement = "bottom-start";

  selectView?: SelectView<TItem>;
  inputRef = React.createRef<HTMLInputElement>();

  itemByKeyKeyValue?: any;
  itemByKey?: TItem;
  itemByKeyLoaded = false;
  destroyed = false;

  constructor(props: SelectInputProps<TItem>) {
    super(props);

    this.state = {
      isInputFocused: false,
      isDirty: false,
      inputValue: "",
      filterToken: "",
      selectVisible: false,
    };

    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onKeydown = this.onKeydown.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.hide = this.hide.bind(this);
    this.clear = this.clear.bind(this);
  }

  componentDidMount() {
    this.destroyed = false;
  }

  componentWillUnmount() {
    this.destroyed = true;
  }

  componentDidUpdate(prevProps: SelectInputProps<TItem>) {
    if (this.itemByKeyLoaded && this.props.items !== prevProps.items && Array.isArray(this.props.items)) {
      this.itemByKeyLoaded = false;
      this.itemByKey = undefined;
      this.itemByKeyKeyValue = undefined;
      this.forceUpdate();
    }
  }

  render() {
    const selectVisible = this.state.selectVisible;
    const selectView = selectVisible ? this.renderSelectView() : undefined;
    const {
      value,
      items,
      itemKey,
      itemKeyAsModel,
      itemRender,
      itemsRender,
      display,
      itemAnnotation,
      placement,
      nonStrict,
      emptyOption,
      disableNoResultFeedback,
      searchValue,
      filtering,
      hideClearIcon,
      hideHelperIcons,
      pendingSpinner,
      onChange,
      className,
      wrapperClassName,
      onChangeNonStrict,
      errorAnnotation,
      placeholder,
      ...restProps
    } = this.props;
    const classNames = combineClassNames(className, SelectInput.rootClassName, errorAnnotation && "has-error");
    const helperIcon = this.renderHelperIcon();
    const clsWrapper = combineClassNames(
      "input-wrapper",
      SelectInput.rootClassName,
      wrapperClassName,
      helperIcon ? "has-helper-icon" : undefined,
      this.state.isInputFocused && "focused",
      this.props.disabled && "disabled",
      value !== undefined ? "filled" : "empty",
      hideClearIcon ? "hide-clear-icon" : undefined,
      hideHelperIcons ? "hide-helper-icons" : undefined
    );

    return (
      <TrCtx>
        {tr => (
          <div className={clsWrapper}>
            <input
              {...restProps}
              className={classNames}
              type="text"
              placeholder={placeholder && tr(placeholder)}
              ref={this.inputRef}
              value={this.getViewValue(tr)}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onChange={this.onChange}
              onKeyDown={this.onKeydown}
              onClick={this.onClick}
            />
            {helperIcon}
            {errorAnnotation && <div className="error-field-annotation">{errorAnnotation}</div>}
            {selectView}
          </div>
        )}
      </TrCtx>
    );
  }

  renderHelperIcon() {
    if (this.props.pendingSpinner) {
      return <Icon className="input-icon" symbol="spinner" spin />;
    }

    if (this.props.disabled || this.props.readOnly || this.props.hideHelperIcons) return null;

    return this.props.value !== undefined && !this.props.hideClearIcon ? (
      <ClickableIcon className="input-icon" symbol="times" onClick={this.clear} />
    ) : (
      <Icon className="input-icon" symbol="angleDown" />
    );
  }

  renderSelectView() {
    const placement = this.props.placement || SelectInput.defaultPlacement;
    const searchText = this.getSearchTextForView();
    const modelKeyValue = this.getModelKeyValue();

    return (
      <SelectView
        items={this.props.items}
        searchText={searchText}
        placement={placement}
        popoverRef={this.inputRef.current!}
        onSelect={this.onSelect}
        onOutsideClick={this.hide}
        selectedItemKey={modelKeyValue}
        display={this.props.display}
        itemAnnotation={this.props.itemAnnotation}
        itemKey={this.props.itemKey}
        itemRender={this.props.itemRender}
        itemsRender={this.props.itemsRender}
        emptyOption={this.props.emptyOption}
        searchValue={this.props.searchValue}
        filtering={this.props.filtering}
        disableNoResultFeedback={this.props.disableNoResultFeedback}
        ref={view => (this.selectView = view || undefined)}
      />
    );
  }

  getSearchTextForView() {
    const { value, nonStrict } = this.props;

    if (value === undefined || value === null || value === "" || nonStrict) return this.state.filterToken;
    else return "";
  }

  onFocus(ev: React.FocusEvent<HTMLInputElement>) {
    if (this.props.readOnly) return;

    this.setState({
      isInputFocused: true,
      isDirty: false,
      inputValue: ev.target.value,
      filterToken: "",
    });
    this.inputRef.current && this.inputRef.current.select();
    this.props.onFocus && this.props.onFocus(ev);
  }

  onBlur(ev: React.FocusEvent<HTMLInputElement>) {
    if (this.props.readOnly) return;

    this.setState({
      isInputFocused: false,
      isDirty: false,
      inputValue: "",
      filterToken: "",
      selectVisible: false,
    });
    this.props.onBlur && this.props.onBlur(ev);
  }

  onClick(ev: React.MouseEvent<HTMLInputElement>) {
    ev.stopPropagation();
    ev.preventDefault();
    ev.nativeEvent.stopImmediatePropagation();

    if (this.props.readOnly) return;

    if (this.show()) {
      this.inputRef.current && this.inputRef.current.select();
    } else {
      this.hide();
    }
    this.props.onClick && this.props.onClick(ev);
  }

  onChange(ev: React.ChangeEvent<HTMLInputElement>) {
    const filterToken = ev.target.value;

    if (!this.props.nonStrict) {
      this.updateModel(undefined);
    } else {
      this.updateModel(ev.target.value);
    }

    if (!this.state.selectVisible) this.show();
    this.setState({
      inputValue: ev.target.value,
      isDirty: true,
    });
    this.updateFilterToken(filterToken);
  }

  lastTokenDebouncedSetTimer: number | undefined;

  updateFilterToken(filterToken: string) {
    if (this.lastTokenDebouncedSetTimer) {
      window.clearTimeout(this.lastTokenDebouncedSetTimer);
      this.lastTokenDebouncedSetTimer = undefined;
    }

    const debounceTime = this.getFilterTokenUpdateDebounceTime();
    if (!debounceTime) {
      this.setState({
        filterToken,
      });
    } else {
      this.lastTokenDebouncedSetTimer = window.setTimeout(() => {
        if (this.destroyed) return;

        this.setState({ filterToken });
      }, debounceTime);
    }
  }

  getFilterTokenUpdateDebounceTime(): number {
    if (Array.isArray(this.props.items)) {
      return 0;
    }

    // async items provider
    const ms = this.props.items.getItemsDebounceMs;
    return ms === undefined ? 500 : ms;
  }

  onKeydown(ev: React.KeyboardEvent<HTMLInputElement>) {
    if (this.props.readOnly) return;

    switch (ev.keyCode) {
      case KeyCodes.downarrow:
      case KeyCodes.uparrow:
        ev.preventDefault();
        ev.stopPropagation();
        this.selectView && this.selectView.onKeydown(ev);
        if (!this.selectView) this.show();
        break;
      case KeyCodes.enter:
      case KeyCodes.tab:
        this.selectView && this.selectView.onKeydown(ev);
        break;

      case KeyCodes.escape:
        if (this.state.selectVisible) this.hide();
        break;

      default:
        break;
    }

    this.props.onKeyDown && this.props.onKeyDown(ev);
  }

  onSelect<TItem>(value: TItem | undefined) {
    this.updateModel(value);
    this.hide();
    this.setState({ isDirty: false });
    //this.getInputElement().blur();
  }

  updateModel(newValue: any | undefined, nonStrictValue?: boolean) {
    let modelValue = newValue;

    if (nonStrictValue) {
      if (this.props.onChangeNonStrict) {
        this.props.onChangeNonStrict(modelValue);
      }
    } else {
      if (this.props.onChange) {
        this.props.onChange(modelValue);
        if (this.props.itemKeyAsModel && !Array.isArray(this.props.items)) {
          this.itemByKey = modelValue;
          this.itemByKeyKeyValue = modelValue !== undefined ? this.getItemKey(modelValue) : undefined;
          this.itemByKeyLoaded = true;
        }
      }
    }

    if (!this.state.isInputFocused || newValue !== undefined) {
      this.setState({
        inputValue: this.formatItemDisplay(newValue),
      });
    }
  }

  getViewValue(tr: TrFunction) {
    if (this.state.isInputFocused && this.state.isDirty) {
      return this.state.inputValue;
    } else {
      const displayValue = this.formatModelDisplay(tr);
      return displayValue;
    }
  }

  formatModelDisplay(tr: TrFunction): string {
    const { value } = this.props;

    let displayValue = "";
    let annotationValue = "";

    if (value === undefined || value == null) {
      return "";
    } else {
      if (this.props.itemKeyAsModel) {
        const keyValue = value;
        if (this.itemByKeyKeyValue !== keyValue) {
          this.tryToLoadItemByKey(keyValue);
        }

        displayValue = tr(this.formatItemDisplay(this.itemByKey || value));
        annotationValue = this.props.itemAnnotation
          ? selectUtils.formatItemDisplay(this.itemByKey || value, this.props.itemAnnotation)
          : "";
      } else {
        displayValue = tr(this.formatItemDisplay(value));
        annotationValue = this.props.itemAnnotation
          ? selectUtils.formatItemDisplay(value, this.props.itemAnnotation)
          : "";
      }

      return annotationValue ? `${displayValue}, ${annotationValue}` : displayValue;
    }
  }

  tryToLoadItemByKey(keyValue: any) {
    this.itemByKey = undefined;
    this.itemByKeyKeyValue = keyValue;
    this.itemByKeyLoaded = false;

    if (Array.isArray(this.props.items)) {
      const item = this.props.items.filter(x => this.getItemKey(x) === keyValue)[0];
      this.itemByKeyLoaded = true;
      this.itemByKey = item;
    } else {
      const asyncItemProvider = this.props.items;
      asyncItemProvider.getItemByKey(keyValue).then(res => {
        if (this.itemByKeyKeyValue !== keyValue) return;
        this.itemByKey = res;
        this.itemByKeyLoaded = true;
        this.forceUpdate();
      });
    }
  }

  formatItemDisplay(item: any): string {
    return selectUtils.formatItemDisplay(item, this.props.display);
  }

  getItemKey(item: any) {
    if (typeof item === "string") return item;
    if (typeof item === "number") return item;

    return item[this.props.itemKey || "id"];
  }

  getModelKeyValue(): any {
    const { value } = this.props;

    if (value === undefined || value == null) {
      return undefined;
    } else {
      if (this.props.itemKeyAsModel) {
        return value;
      } else {
        return this.getItemKey(value);
      }
    }
  }

  show() {
    if (this.state.selectVisible) return false;

    this.setState({
      selectVisible: true,
    });
    return true;
  }

  hide() {
    if (!this.state.selectVisible) return;

    this.setState({
      selectVisible: false,
    });
  }

  toggle() {
    this.setState({
      selectVisible: !this.state.selectVisible,
    });
  }

  clear() {
    if (this.props.disabled) return;

    this.onSelect(undefined);
  }
}
